import { Link, StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import React from "react"
import Seo from "../../../components/seo"

const Glossary = () => {
  const subTitle = 'Dog Agility Glossary'
  return (
    <StaticQuery
      query={graphql`{
  site {
    siteMetadata {
      title
      social {
        facebook
      }
    }
  }
  file(relativePath: {eq: "backgrounds/turf-ring.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(
    sort: {fields: [frontmatter___title]}
    filter: {frontmatter: {date: {ne: null}, draft: {ne: true}, tags: {}}, fields: {slug: {glob: "/blog/agility/glossary/**"}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMM YYYY")
          title
        }
        excerpt
      }
    }
  }
}
`}
      render={({ site: { siteMetadata }, file, allMdx: { edges } }) => {
        const layoutImage = file ? file.childImageSharp.gatsbyImageData : null
        const entries = edges ? (
          <ul className="featured list-unstyled">
            {edges.map(edge => {
              return (<li key={edge.node.id} className="mb-4">
                <h4>
                  <Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
                </h4>
                <small>{edge.node.frontmatter.date} - {edge.node.excerpt}</small>
              </li>)
            })
            }
          </ul>) : null
        return (
          <Layout title={siteMetadata.title} subtitle={subTitle} image={layoutImage} meta={siteMetadata}>
            <Seo
              title='Dog Agility Glossary'
              description='Dog Agility Glossary'
            />
            <h1>Dog Agility Glossary</h1>
            <p>I've collected my blog articles that define dog agility terms and I have grouped them together on this page in alphabetical order.</p>
            <p>Don't forget the search icon in the menu to find articles on your topic!</p>
            <p>Please contact me if you'd like me to add any other terms.</p>
            <hr />
            {entries}
          </Layout>
        )
      }}
    />
  );
}

export default Glossary;